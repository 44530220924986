import type { RoadRoutingFerryPreferenceEnum, SeaRoutingAreaEnum } from '../../../services/graphql/generated';
import {
  EmissionFreightCargoTypeEnum,
  EmissionFreightDistanceUnitEnum,
  EmissionFreightWeightUnitEnum,
} from '../../../services/graphql/generated';
import { CalculatorLocationTypeEnum, type CalculatorParentState } from '../types';

export const example1State = (
  autoTransshipment?: boolean,
  ferryPreference?: RoadRoutingFerryPreferenceEnum,
  seaRestrictedAreas?: SeaRoutingAreaEnum[],
): CalculatorParentState => ({
  cargoDetail: {
    weight: 1,
    weight_unit: EmissionFreightWeightUnitEnum.Teu,
    cargo_type: EmissionFreightCargoTypeEnum.Average,
    auto_transshipment: autoTransshipment,
  },
  legs: [
    {
      detail: {
        vehicle_used: 'SCS',
        vessel: {
          id: '9631967',
          imo: '9631967',
          name: 'APL LION CITY',
        },
        ferry_preference: ferryPreference,
        sea_restricted_areas: seaRestrictedAreas,
      },
      from: {
        id:
          // @ts-expect-error -- enabling strict mode
          process.env.NODE_ENV === 'development'
            ? '3ce23419-3dfb-4e1f-bf01-eee1238bf4a4'
            : 'c1ee68e9-30a2-43dd-af85-5add31b17404',
        name: 'Southampton',
        display_name: 'Port of Southampton, United Kingdom',
        type: CalculatorLocationTypeEnum.PORT,
        country: 'United Kingdom',
        country_code_alpha_2: 'gb',
        country_code_alpha_3: 'gbr',
        coordinates: [50.8776, -1.3903],
        unlocode: 'GBSOU',
      },
      to: {
        id:
          // @ts-expect-error -- enabling strict mode
          process.env.NODE_ENV === 'development'
            ? '44df8e48-f4a2-49c4-9e86-6470314c87e6'
            : 'c9bde4ca-ba71-43b5-b475-eb404a8e4633',
        name: 'Singapore',
        display_name: 'Port of Singapore, Singapore',
        type: CalculatorLocationTypeEnum.PORT,
        country: 'Singapore',
        country_code_alpha_2: 'sg',
        country_code_alpha_3: 'sgp',
        coordinates: [1.2576, 103.85457],
        unlocode: 'SGSIN',
      },
      id: 0,
      isSaved: true,
      stops: [
        {
          id:
            // @ts-expect-error -- enabling strict mode
            process.env.NODE_ENV === 'development'
              ? '6c6f80b1-828a-45bf-87a9-7a1fb3e570bc'
              : '6c6f80b1-828a-45bf-87a9-7a1fb3e570bc',
          name: 'Antwerp',
          display_name: 'Antwerp, Belgium',
          type: CalculatorLocationTypeEnum.PORT,
          country: 'Belgium',
          country_code_alpha_2: 'be',
          country_code_alpha_3: 'bel',
          coordinates: [51.30249, 4.31146],
          unlocode: 'BEANR',
        },
        {
          id:
            // @ts-expect-error -- enabling strict mode
            process.env.NODE_ENV === 'development'
              ? '3c6821c3-b7c1-4446-8d3a-fcaf6a979839'
              : '3c6821c3-b7c1-4446-8d3a-fcaf6a979839',
          name: 'Le Havre',
          display_name: 'Le Havre, France',
          type: CalculatorLocationTypeEnum.PORT,
          country: 'France',
          country_code_alpha_2: 'fr',
          country_code_alpha_3: 'fra',
          coordinates: [49.47265, 0.1462],
          unlocode: 'FRLEH',
        },
      ],
    },
    {
      id: 3,
      from: {
        id:
          // @ts-expect-error -- enabling strict mode
          process.env.NODE_ENV === 'development'
            ? '44df8e48-f4a2-49c4-9e86-6470314c87e6'
            : 'c9bde4ca-ba71-43b5-b475-eb404a8e4633',
        name: 'Singapore',
        display_name: 'Port of Singapore, Singapore',
        type: CalculatorLocationTypeEnum.PORT,
        country: 'Singapore',
        country_code_alpha_2: 'sg',
        country_code_alpha_3: 'sgp',
        coordinates: [1.2576, 103.85457],
        unlocode: 'SGSIN',
      },
      to: {
        id:
          // @ts-expect-error -- enabling strict mode
          process.env.NODE_ENV === 'development'
            ? '44df8e48-f4a2-49c4-9e86-6470314c87e6'
            : 'c9bde4ca-ba71-43b5-b475-eb404a8e4633',
        name: 'Singapore',
        display_name: 'Port of Singapore, Singapore',
        type: CalculatorLocationTypeEnum.PORT,
        country: 'Singapore',
        country_code_alpha_2: 'sg',
        country_code_alpha_3: 'sgp',
        coordinates: [1.2576, 103.85457],
        unlocode: 'SGSIN',
      },
      isSaved: true,
      detail: {
        vehicle_used: 'LST',
        sea_restricted_areas: seaRestrictedAreas,
        ferry_preference: ferryPreference,
      },
    },
    {
      detail: {
        vehicle_used: 'SCS',
        ferry_preference: ferryPreference,
        sea_restricted_areas: seaRestrictedAreas,
      },
      distance_unit: EmissionFreightDistanceUnitEnum.Km,
      isSaved: true,
      id: 2,
      to: {
        id:
          // @ts-expect-error -- enabling strict mode
          process.env.NODE_ENV === 'development'
            ? 'c32b2749-30ad-424b-8e56-72f0b4a4672a'
            : '052fcada-b562-469d-9975-53b18ea44c5a',
        name: 'Manila',
        display_name: 'Port of Manila, Philippines',
        type: CalculatorLocationTypeEnum.PORT,
        country: 'Philippines',
        country_code_alpha_2: 'ph',
        country_code_alpha_3: 'phl',
        coordinates: [14.6153, 120.9468],
        unlocode: 'PHMNL',
      },
      from: {
        id:
          // @ts-expect-error -- enabling strict mode
          process.env.NODE_ENV === 'development'
            ? '44df8e48-f4a2-49c4-9e86-6470314c87e6'
            : 'c9bde4ca-ba71-43b5-b475-eb404a8e4633',
        name: 'Singapore',
        display_name: 'Port of Singapore, Singapore',
        type: CalculatorLocationTypeEnum.PORT,
        country: 'Singapore',
        country_code_alpha_2: 'sg',
        country_code_alpha_3: 'sgp',
        coordinates: [1.2576, 103.85457],
        unlocode: 'SGSIN',
      },
    },
  ],
});
